import { render, staticRenderFns } from "./notes-form.vue?vue&type=template&id=a4b88aee&scoped=true"
import script from "./notes-form.vue?vue&type=script&lang=js"
export * from "./notes-form.vue?vue&type=script&lang=js"
import style0 from "./notes-form.vue?vue&type=style&index=0&id=a4b88aee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4b88aee",
  null
  
)

export default component.exports