<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-row>
      <v-col class="mt-0 pt-0 pb-0 xs" cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pb-2" cols="12">
        <v-card>
          <v-card-text>
            <ul style="color: red">
              <li v-for="(error, idx) in vm.errors" :key="idx">
                {{ error.description }}
              </li>
            </ul>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12"><h4 class="my-0" style="color:#156a59">Details</h4></v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="vm.contactType"
                      :items="contactTypes"
                      key="contactTypes"
                      label="Contact type"
                      data-vv-as="Contact type"
                     
                      name="contactType"
                      v-validate="'required'"
                      :error-messages="errors.collect('contactType')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                  <v-switch 
                   v-model="isTime"
                   hide-details
                  label="Time"
                  
                   >

                   <template #prepend><v-label>Volume</v-label></template>
                   
                   </v-switch>
                   </v-col>
                 
                  <v-col cols="6" v-if="isTime">
                    <v-menu
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="vm.startDateString"
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vm.startDateString"
                          label="Start date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          v-validate="'required'"
                          data-vv-as="Start date"
                          name="startDateString"
                          :error-messages="errors.collect('startDateString')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vm.startDateString"
                        no-title
                        scrollable
                        color="green lighten-1"
                        dark
                      >
                        <v-btn text color="primary" @click="startDateMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.startDateMenu.save(vm.startDateString)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" v-if="isTime">
                    <v-spacer></v-spacer>
                    <v-menu
                      ref="startTimeMenu"
                      v-model="startTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="vm.startTime"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                     >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vm.startTime"
                          label="Start time"
                          prepend-icon="mdi-clock-outline"
                          v-validate="'required'"
                          data-vv-as="startTime"
                          name="startTime"
                          :error-messages="errors.collect('startTime')"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startTimeMenu"
                        format="24hr"
                        v-model="vm.startTime"
                        full-width
                        @click:minute="$refs.startTimeMenu.save(vm.startTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" v-if="isTime">
                    <v-menu
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="vm.endDate"
                      transition="scale-transition"
                      offset-y
                      full-width
                      min-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vm.endDateString"
                          label="End date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          v-validate="'required'"
                          data-vv-as="End date"
                          name="endDateString"
                          :error-messages="errors.collect('endDateString')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="vm.endDateString"
                        no-title
                        scrollable
                        color="green lighten-1"
                        dark
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="endDateMenu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.endDateMenu.save(vm.endDateString)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" v-if="isTime">
                    <v-menu
                      ref="endTimeMenu"
                      v-model="endTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="vm.endTime"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                       max-width="290px"
                       min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="vm.endTime"
                          label="End time"
                          prepend-icon="mdi-clock-outline"
                          readonly
                          v-validate="'required'"
                          data-vv-as="endTime"
                          name="endTime"
                          :error-messages="errors.collect('endTime')"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="endTimeMenu"
                        v-model="vm.endTime"
                        full-width
                        @click:minute="$refs.endTimeMenu.save(vm.endTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12"  v-if="(vm.lineItem)" >
                    <v-text-field
                     v-if="isTime"
                      v-model="vm.duration"
                      key="duration"
                      :readonly="vm.lineItem.unitOfMeasure !== 'EA'"
                      :label="getUnitOfMeasureLabel(vm.lineItem.unitOfMeasure)"
                      v-validate="'required|min_value:0'"
                      data-vv-as="duration"
                      name="duration"
                      :v-text="getDuration()"
                      :error-messages="errors.collect('duration')"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" v-if="!isTime">
                    <v-text-field
                      v-model="vm.quantity"
                      label="Quantity"
                      key="quatity"
                      type="numbers"
                      v-validate="'required'"
                      data-vv-as="quantity"
                      name="quantity"
                      :error-messages="errors.collect('quantity')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="vm.notes"
                      :auto-grow="true"
                      label="Notes"
                      key="notes"
                      filled
                      v-validate="'required'"
                      data-vv-as="notes"
                      name="notes"
                      :error-messages="errors.collect('notes')"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" v-if="this.isBiller">
                <v-col cols="12"><h4 class="my-0" style="color:#156a59">Billing</h4></v-col>
                <v-col cols="12" v-if="this.isBiller">
                  <v-select
                    v-model="vm.budgetItem"
                    :items="budgetItems"
                    label="Budget item"
                    data-vv-as="budget item"
                    item-text="title"
                    name="budgetItem"
                    key="budgetItem"
                    @change="onbudgetItemChange"
                    v-validate="'required'"
                    return-object
                    :error-messages="errors.collect('budgetItem')"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="this.isBiller">
                  <v-select
                    v-if="vm.budgetItem"
                    v-model="vm.lineItem"
                    :items="lineItems"
                    @change="onlineItemChange"
                    label="Line item"
                    data-vv-as="line item"
                    :key="vm.budgetItem.id"
                    item-text="supportItem"
                    name="lineItem"
                    v-validate="'required'"
                    return-object
                    :error-messages="errors.collect('lineItem')"
                  ></v-select>
                  <span
                    v-if="
                      vm.lineItem !== undefined &&
                      vm.lineItem !== null &&
                      vm.lineItem.isOld !== undefined &&
                      vm.lineItem.isOld !== null &&
                      vm.lineItem.isOld
                    "
                  >
                    <v-icon color="orange darken-2">mdi-alert</v-icon>
                    <span class="orange--text text--darken-2"
                      >Using outdated price guide
                    </span>
                  </span>
                  <div v-if="vm.lineItem">
                    {{ vm.lineItem.supportItemDescription }} ({{
                      vm.lineItem.supportItemNumber
                    }})
                  </div>
                </v-col>
                <v-col cols="12" v-if="this.isBiller && vm.lineItem" key="nDISManageddiv">
                  <div v-if="vm.lineItem">
                    <v-checkbox
                      v-model="vm.lineItem.nDISManaged"
                      key="nDISManaged"
                      name="nDISManaged"
                      label="NDIS Managed"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" v-if="this.isBiller">
                  <v-select
                    v-model="vm.gstType"
                    :items="gstTypes"
                    label="GST type"
                    data-vv-as="GST type"
                    name="gstType"
                    key="gstType"
                    item-text="description"
                    v-validate="'required'"
                    return-object
                    :error-messages="errors.collect('gstType')"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="this.isBiller">
                  <v-select
                    v-model="vm.claimType"
                    :items="claimTypes"
                    label="Claim type"
                    key="claimType"
                    data-vv-as="claimType"
                    name="claimType"
                    item-text="description"
                   
                    v-validate="'required'"
                    return-object
                    :error-messages="errors.collect('claimType')"
                  ></v-select>
                </v-col>
                 <v-col
            cols="12"
            v-if="this.isBiller && vm.claimType!==undefined && vm.claimType.code=='CANC'" >
                  <v-select
                   
                    v-model="vm.cancellationReason"
                    :items="cancelationReasons"
                    label="Cancellation Reason"
                    item-text="description"
                    key="cancellationReason"
                    data-vv-as="cancellationReason"
                    name="cancellationReason"
                    v-validate="'required'"
                    return-object
                    :error-messages="errors.collect('cancellationReason')"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="this.isBiller">
                  <v-text-field
                    v-model="vm.inKindFundingProgram"
                    label="Name of the in-kind program, if applicable to the support item entered"
                    v-validate="'max:30'"
                    counter="30"
                    key="inKindFundingProgram"
                    data-vv-as="inKindFundingProgram"
                    name="inKindFundingProgram"
                    :error-messages="errors.collect('inKindFundingProgram')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="vm.lineItem && this.isBiller">
                  <v-radio-group
                    v-model="vm.priceLimit"
                    @change="onLimitChange"
                    label="Price limit"
                    key="price"
                  >
                    <v-radio
                      v-if="vm.lineItem.priceLimit"
                      :value="vm.lineItem.priceLimit"
                      :label="`Price Limit: $${vm.lineItem.priceLimit} - (Pre July 2019 catalogue) `"
                    ></v-radio>
                    <v-radio
                      v-if="vm.lineItem.priceLimitNationalNonRemote"
                      :value="vm.lineItem.priceLimitNationalNonRemote"
                      :label="`National non remote: $${vm.lineItem.priceLimitNationalNonRemote}`"
                    ></v-radio>
                    <v-radio
                      v-if="vm.lineItem.priceLimitNationalRemote"
                      :value="vm.lineItem.priceLimitNationalRemote"
                      :label="`National remote: $${vm.lineItem.priceLimitNationalRemote}`"
                    ></v-radio>
                    <v-radio
                      v-if="vm.lineItem.priceLimitVeryRemote"
                      :value="vm.lineItem.priceLimitVeryRemote"
                      :label="`Very remote: $${vm.lineItem.priceLimitVeryRemote}`"
                    ></v-radio>
                    <v-radio
                      v-if="vm.lineItem.priceLimitActNswQldVic"
                      :value="vm.lineItem.priceLimitActNswQldVic"
                      :label="`ACT, NSW, QLD, VIC: $${vm.lineItem.priceLimitActNswQldVic}`"
                    ></v-radio>
                    <v-radio
                      v-if="vm.lineItem.priceLimitNtSaTasWa"
                      :value="vm.lineItem.priceLimitNtSaTasWa"
                      :label="`NT SA TAS WA: $${vm.lineItem.priceLimitNtSaTasWa}`"
                    ></v-radio>
                  </v-radio-group>

                  Price controlled at ${{
                    vm.lineItem.priceControl === "y"
                      ? vm.priceLimit
                      : vm.price == 0
                      ? ""
                      : vm.price
                  }}
                  per {{ getUnitOfMeasure(vm.lineItem.unitOfMeasure) }}
                </v-col>
                <v-col
                  cols="12"
                  v-if="vm.lineItem && vm.lineItem.priceControl !== 'y' && this.isBiller"
                >
                  <v-text-field
                    v-model="vm.price"
                    key="priceper"
                    :label="'Price per ' + getUnitOfMeasure(vm.lineItem.unitOfMeasure)"
                    v-validate="'required'"
                    data-vv-as="price"
                    name="price"
                    :error-messages="errors.collect('price')"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <span style="font-size: 0.5em; margin-left: 10px"
        >GoalId:{{ currentGoal.id }} | NoteID: {{ vm.id }}</span
      >
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as notesService from "../../../services/progressNotesService";
import * as budgetItemService from "../../../services/budgetItemService";
import * as goalService from "../../../services/goalService";
import moment from "moment";
Vue.use(VeeValidate);
export default {
  name: "notes-form",
  data: () => ({
    duration: "",

    startTimeMenu: false,
    startDateMenu: false,
    budgetItem: {},
    endTimeMenu: false,
    counter: 0,
    endDateMenu: false,
    isBiller: false,
    isTime: true,
    budgetItems: [],
    lineItems: [],
    vm: {},
    gstTypes: [
      { code: "P1", description: "Tax Claimable (10%)" },
      { code: "P2", description: "GST Free" },
      { code: "P5", description: "GST out of Scope" },
    ],
    claimTypes: [
      { code: "", description: "Standard Service Charges" },
      { code: "CANC", description: "Cancellation Charges" },
      { code: "REPW", description: " Report Writing Charges" },
      { code: "TRAN", description: "Travel Charges" },
    ],
    cancelationReasons: [
      { code: "NSDH", description: "No show due to health reason" },
      { code: "NSDF", description: "No show due to family issues" },
      {
        code: "NSDT",
        description: "No show due to unavailability of transport",
      },
      { code: "NSDO", description: "No show - Other" },
    ],
    contactTypes: ["Phone", "Email", "Meeting/Appointment", "Report Writing", "Travel"],
    supportCategory: [
      {
        name: "Core support",
        supportTypes: [
          {
            name: "Assistance with daily life (Includes SIL)",
            registrationGroups: ["1", "3", "4", "7", "15", "20", "30"],
          },
          { name: "Transport", registrationGroups: ["8"] },
          { name: "Consumables", registrationGroups: ["3", "21"] },
          {
            name: "Assistance with social and community participation",
            registrationGroups: ["4", "25", "36"],
          },
        ],
      },
      {
        name: "Capital supports",
        supportTypes: [
          {
            name: "Assistive technology",
            registrationGroups: [
              "3",
              "8",
              "9",
              "11",
              "12",
              "13",
              "19",
              "22",
              "23",
              "24",
              "34",
              "35",
            ],
          },
          { name: "Home modifications", registrationGroups: ["3", "11", "31"] },
        ],
      },
      {
        name: "capacity building Supports",
        supportTypes: [
          {
            name: "Coordination of supports",
            registrationGroups: ["6", "17", "32"],
          },
          {
            name: "Improved living arrangements",
            registrationGroups: ["1", "6"],
          },
          {
            name: "Increased social and community participation",
            registrationGroups: ["6", "16", "17", "25"],
          },
          {
            name: "Finding and keeping a job",
            registrationGroups: ["2", "28", "33"],
          },
          { name: "Improved relationships", registrationGroups: ["10", "17"] },
          {
            name: "Improved health and well-being",
            registrationGroups: ["26", "28"],
          },
          { name: "Improved learning", registrationGroups: ["2"] },
          {
            name: "Choice & control (Improved life choices)",
            registrationGroups: ["27"],
          },
          {
            name: "Improved daily living",
            registrationGroups: ["6", "14", "17", "18", "19", "28", "29"],
          },
        ],
      },
    ],
    filteredRegistrationGroups: [],
    registrationGroups: [
      { id: "1", name: "Accommodation / Tenancy Assistance" },
      {
        id: "2",
        name: "Assist to access/Maintain employ/Ed (Assist Access/Maintain Employ)",
      },
      { id: "3", name: "Assistive products for personal care and safety" },
      {
        id: "4",
        name: "High Intensity Daily Personal Activities (Assist Integrate School/Ed)",
      },
      { id: "5", name: "Personal Mobility Equipment" },
      {
        id: "6",
        name:
          "Assistance in coordinating or managing life stages, transitions and supports",
      },
      {
        id: "7",
        name: "Daily Personal Activities (Assist Personal Activities)",
      },
      { id: "8", name: "Assistance with travel/transport arrangements" },
      { id: "9", name: "Vehicle Modifications" },
      { id: "10", name: "Behaviour Support" },
      { id: "11", name: "Home modification design and construction" },
      { id: "12", name: "Assistive equipment for recreation" },
      { id: "13", name: "Vision Equipment" },
      { id: "14", name: "Community nursing care for high needs" },
      {
        id: "15",
        name: "Assistance with daily life tasks in a group or shared living arrangement",
      },
      { id: "16", name: "Innovative Community Participation" },
      { id: "17", name: "Development of daily living and life skills" },
      { id: "18", name: "Early Intervention supports for early childhood" },
      {
        id: "19",
        name: "Specialised Hearing Services (Equipment Special Assess Setup)",
      },
      { id: "20", name: "Household tasks" },
      { id: "21", name: "Interpreting and translation" },
      { id: "22", name: "Hearing Equipment" },
      { id: "23", name: "Assistive products for household tasks" },
      { id: "24", name: "Communication and information equipment" },
      {
        id: "25",
        name: "Participation in community, social and civic activities",
      },
      {
        id: "26",
        name:
          "Exercise Physiology and Physical Wellbeing activities (Physical Wellbeing)",
      },
      {
        id: "27",
        name: "Management of funding for supports in participants plan",
      },
      { id: "28", name: "Therapeutic Supports" },
      { id: "29", name: "Specialised Driver Training" },
      { id: "30", name: "Assistance Animals (Other Innovative Supports)" },
      { id: "31", name: "Specialised Disability Accommodation" },
      { id: "32", name: "Support Coordination" },
      { id: "33", name: "Specialised Supported Employment" },
      { id: "34", name: "Hearing Services" },
      { id: "35", name: "Customised Prosthetics" },
      { id: "36", name: "Group and Centre Based Activities" },
    ],
    form: {},
  }),
  watch: {
    note(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal() {
      return this.$store.getters.currentGoal;
    },
    currentProgressNote() {
      return this.$store.getters.currentProgressNote;
    },
  },
  methods: {
    init() {
      var me = this;
      me.vm = me.currentProgressNote;
      console.log(me.vm);
      if (me.vm.id === undefined) {
        me.vm.priceLimit = 0;
        me.vm.nDISNumber = me.currentCase.participantDetails.nDISNumber;
        me.vm.caseId = me.currentCase.id;
        me.vm.organisationId = me.currentOrganisation.id;

        me.vm.goalId = me.currentGoal.id;
        me.vm.participantName = `${me.currentCase.participantDetails.firstname} ${this.currentCase.participantDetails.lastname}`;

        me.vm.participantFirstname = me.currentCase.participantDetails.firstname;
        me.vm.participantLastname = me.currentCase.participantDetails.lastname;

        const org = me.$store.getters.currentOrganisation;
        if (org.defaults !== undefined && org.defaults.billing != undefined) {
          me.vm.contactType = org.defaults.billing.contactType;
          me.vm.gstType = org.defaults.billing.gstType;
          me.vm.claimType = org.defaults.billing.claimType;
          if (org.defaults.billing.cancellationReason !== undefined) {
            me.vm.cancellationReason = org.defaults.billing.cancellationReason;
          }
        }

        //set defaults if set at the goal level
        if (!me.$utils.isEmpty(me.currentGoal.budgetItem)) {
          me.vm.budgetItem = me.currentGoal.budgetItem;
          if (!me.$utils.isEmpty(me.vm.budgetItem)) {
            this.lineItems = me.vm.budgetItem.lineItems;
          }
          if (!me.$utils.isEmpty(me.currentGoal.lineItem)) {
            me.vm.lineItem = me.currentGoal.lineItem;
            me.onlineItemChange(me.vm.lineItem); //call to load org defaults
          }
        }
      }
      budgetItemService
        .list(me.currentOrganisation.id, me.vm.caseId)
        .then((querySnapshot) => {
          me.budgetItems = querySnapshot.docs.map((d) => d.data());

          if (!me.$utils.isEmpty(me.vm.budgetItem)) {
            var match = me.budgetItems.find((x) => {
              return x.id == me.vm.budgetItem.id;
            });
            me.lineItems = match.lineItems;
            console.log(me.lineItems);
          }
        });

      me.isBiller = false;

      if (
        me.user.roles.indexOf("Billing") > -1 ||
        me.user.roles.indexOf("System Administrator") > -1 ||
        me.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        me.isBiller = true;
      }
    },
    getDuration() {
      if (
        this.vm.startDateString !== undefined &&
        this.vm.startTime != undefined &&
        this.vm.endDateString !== undefined &&
        this.vm.endTime != undefined
      ) {
        let startDt = moment(
          new Date(this.vm.startDateString.replace(/-/g, "/") + " " + this.vm.startTime)
        );
        let endDt = moment(
          new Date(this.vm.endDateString.replace(/-/g, "/") + " " + this.vm.endTime)
        );
        let duration = moment.duration(endDt.diff(startDt));

        switch (this.vm.lineItem.unitOfMeasure) {
          case "H":
            this.vm.duration = duration.asHours();
            break;
          case "D":
            this.vm.duration = duration.asDays();
            break;
          case "WK":
            this.vm.duration = duration.asWeeks();
            break;
          case "MON":
            this.vm.duration = duration.asMonths();
            break;
          case "YR":
            this.vm.duration = duration.asYears();
            break;
          default:
            break;
        }
        return parseFloat(+this.vm.duration).toFixed(2);
      }
    },
    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    getUnitOfMeasure(key) {
      let value = "";
      switch (key) {
        case "H":
          value = "hour";
          break;
        case "D":
          value = "day";
          break;
        case "WK":
          value = "week";
          break;
        case "MON":
          value = "month";
          break;
        case "YR":
          value = "year";
          break;
        case "EA":
          value = "item";
          break;
        default:
          break;
      }
      return value;
    },
    getUnitOfMeasureLabel(key) {
      let value = "";
      switch (key) {
        case "H":
          value = "Duration (Hours)";
          break;
        case "D":
          value = "Duration (Days)";
          break;
        case "WK":
          value = "Duration (Weeks)";
          break;
        case "MON":
          value = "Duration (Months)";
          break;
        case "YR":
          value = "Duration (Years)";
          break;
        case "EA":
          value = "Number of items";
          break;
        default:
          break;
      }
      return value;
    },
    filterRegistrationGroups() {
      let me = this;
      me.filteredRegistrationGroups = [];

      if (this.vm.supportType != undefined) {
        me.vm.supportType.registrationGroups.forEach((x) => {
          me.registrationGroups.forEach((y) => {
            if (x == y.id) {
              me.filteredRegistrationGroups.push(y);
            }
          });
        });
      }
      return me.filteredRegistrationGroups;
    },
    close() {
      this.$router.back();
    },
    saveGoal(goal) {
      var me = this;
      goal.isDeleted = false;
      goalService
        .save(me.currentOrganisation.id, me.currentCase.id, goal)
        .then((ref) => {
          if (ref != undefined) {
            goal.id = ref.id;
            me.vm.goalId = goal.id;
          }
          me.$store.commit("setCurrentGoal", goal);
        })
        .catch((error) => {
          console.error("Error saving goal: ", error);
        });
    },
  
    onLimitChange(selectedItem) {
      console.log(selectedItem);
      this.vm.price=selectedItem;
      this.$forceUpdate();
    },
    onbudgetItemChange(selectedItem) {
      this.vm.lineItem=null;
      console.log(selectedItem);
      this.lineItems = selectedItem.lineItems;
      this.$forceUpdate();
    },
    
    onlineItemChange(selectedItem) {
      console.log(selectedItem);
      if (
        selectedItem.priceControl === "y" &&
        selectedItem.priceLimitNationalNonRemote !== undefined
      ) {
        this.vm.priceLimit = selectedItem.priceLimitNationalNonRemote;
      }
      const org = this.currentOrganisation;
      if (
        org.defaults !== undefined &&
        org.defaults.billing != undefined &&
        org.defaults.billing.priceLimit !== undefined
      ) {
        const pl = org.defaults.billing.priceLimit.code;
        if (pl === "priceLimitNationalNonRemote") {
          this.vm.priceLimit =
            selectedItem.priceLimitNationalNonRemote === undefined
              ? selectedItem.priceLimit
              : selectedItem.priceLimitNationalNonRemote;
              this.vm.price=this.vm.priceLimit;
        }
        if (
          pl === "priceLimitNationalRemote" &&
          selectedItem.priceLimitNationalRemote !== undefined &&
          selectedItem.priceLimitNationalRemote > 0
        ) {
          this.vm.priceLimit =
            selectedItem.priceLimitNationalRemote === undefined
              ? selectedItem.priceLimit
              : selectedItem.priceLimitNationalRemote;
              this.vm.price=this.vm.priceLimit;
        }
        if (
          pl === "priceLimitVeryRemote" &&
          selectedItem.priceLimitVeryRemote !== undefined &&
          selectedItem.priceLimitVeryRemote > 0
        ) {
          this.vm.priceLimit =
            selectedItem.priceLimitVeryRemote === undefined
              ? selectedItem.priceLimit
              : selectedItem.priceLimitVeryRemote;
              this.vm.price=this.vm.priceLimit;
        }
        if (
          pl === "priceLimitActNswQldVic" &&
          selectedItem.priceLimitActNswQldVic !== undefined &&
          selectedItem.priceLimitActNswQldVic > 0
        ) {
          this.vm.priceLimit =
            selectedItem.priceLimitActNswQldVic === undefined
              ? selectedItem.priceLimit
              : selectedItem.priceLimitActNswQldVic;
              this.vm.price=this.vm.priceLimit;
        }
        if (
          pl === "priceLimitNtSaTasWa" &&
          selectedItem.priceLimitNtSaTasWa !== undefined &&
          selectedItem.priceLimitNtSaTasWa > 0
        ) {
          this.vm.priceLimit =
            selectedItem.priceLimitNtSaTasWa === undefined
              ? selectedItem.priceLimit
              : selectedItem.priceLimitNtSaTasWa;
              this.vm.price=this.vm.priceLimit;
        }
      }
    },
    validateBeforeSubmit() {
      let me = this;
      this.counter=this.counter+1;
      this.$validator.validateAll().then((result) => {
        if (result) {
          const goal = me.$store.state.goalModule.currentGoal;

          if (goal != undefined && goal.status == "Draft") {
            goal.status = "In Progress";

            me.saveGoal(goal);
          }
          me.vm.isDeleted = false;

         
            me.vm.startDate = new Date(
              me.vm.startDateString.replace(/-/g, "/") + " " + me.vm.startTime
            );
            me.vm.endDate = new Date(
              me.vm.endDateString.replace(/-/g, "/") + " " + me.vm.endTime
            );
         
        
          if (me.vm.price == undefined) {
            me.vm.price = 0;
          }

          if (typeof me.vm.price === "string") {
            me.vm.price = parseFloat(me.vm.price.replace("$", ""));
          }

          if (me.vm.lineItem !== undefined) {
            if (me.vm.lineItem.priceControl == "y") {
              if (me.vm.priceLimit == undefined) {
                me.vm.price = me.vm.lineItem.priceLimit;
                me.vm.priceLimit = me.vm.lineItem.priceLimit;
              } else {
                me.vm.price = me.vm.priceLimit;
              }
            }

            if (me.vm.lineItem.nDISManaged === undefined) {
              me.vm.lineItem.nDISManaged = false;
            }
          }
          if (me.vm.createdBy == undefined) {
            me.vm.createdBy = me.user;
            me.vm.createdOn = new Date();
          }

          me.vm.modifiedBy = me.user;
          me.vm.modifiedOn = new Date();

          //save(orgId, caseId, goalId, payload)
          if(this.counter==1)
          {
          notesService
            .save(me.currentOrganisation.id, me.vm.caseId, me.vm.goalId, me.vm)
            .then((ref) => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              me.$store.dispatch("setToast", {
                message: "Progress note saved",
                color: "primary",
              });
              me.close();
            });}
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>
